<template>
  <v-container fluid>
    <CampaignsOverlay v-if="user && user.role && user.role.name ==='referee'" :key="'campaignOverlay'+overlayKey"
                      :no-dialog="false" :parent="user"
                      :record="user" :user="user"
                      data-context="user"></CampaignsOverlay>

    <!--      <v-flex xs12 class="text-center" mt-5>
            <h1 class="text-xs-center">Willkommen zum GVNRW-Portal</h1>
          </v-flex>
          <v-flex xs12 sm6 offset-sm3 mt-3>

          </v-flex>
          <v-flex xs12 class="text-center" mt-5>
            <v-btn v-if="!isLoggedIn" to="/signin">Anmelden</v-btn>
          </v-flex>-->
    <v-row v-if="!loaded">
      <v-col align="center">
        <v-progress-circular
            color="primary"
            indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>


    <WysiwygView
        v-if="loaded"
        :wysiwyg-content="homeContent"
    ></WysiwygView>


  </v-container>
</template>
<script>
import {HTTP} from "@/axios";
import template from "lodash.template"
import saveItemHelper from "@/helpers/saveItemHelper";

let templateVars = {};
/*const templateArObj = (arOrObj) => {

  const iterateObject = (o) => {
    for(let key in o){
      if(key === 'text'){
        o[key] = template(o[key])(templateVars);
      }else if(Array.isArray(o[key])){
        templateArObj(o[key]);
      }
    }
  };

  if(Array.isArray(arOrObj)){
    arOrObj.forEach(arrayItem => {
      if(Array.isArray(arrayItem)){
        //still array go through it again
        templateArObj(arrayItem);
      }else if(typeof arrayItem === 'object'){
        iterateObject(arrayItem);
      }
    });
  }else{
    //is object
    if(typeof arOrObj === 'object'){
      iterateObject(arOrObj);
    }
  }
};*/

export default {
  name: 'Landing',
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    },
  },
  components: {
    WysiwygView: () => import('@/components/views/WysiwygView'),
    CampaignsOverlay: () => import('@/components/campaigns/CampaignsOverlay'),
  },
  data() {
    return {
      homeContent: null,
      overlayKey: 0,
      loaded: false,
      user: {},
      successMsg: null
    }
  },
  created() {
    this.loadMe();
  },
  methods: {
    success() {
      this.successMsg = "Daten erfolgreich gespeichert";
      //uncomment this to stop reopen campaign tasks after success of saving
      //this.noDialog = true;
      this.overlayKey++;
    },
    failed(er) {
      this.error = er;
    },
    save() {
      saveItemHelper(this);
    },
    async loadUser(id) {
      HTTP.get("/users/" + id).then(user => {
        this.user = user.data;
      });
    },
    async loadMe() {
      if (this.isLoggedIn) {
        //reset from local storage in case of user switch without reloading
        HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
        await HTTP.get("/users/me").then(me => {

          this.loadUser(me.data.id);


          this.$nextTick(() => {
            templateVars = me.data;
            templateVars.userRole = me.data.role.name
            this.loadHomeContent();
          });
        });
      } else {
        this.user = null;
        templateVars = {
          userRole: 'public'
        }
        await this.loadHomeContent()
      }


      /*HTTP.get("/mein-verein/"+this.clubId).then((r)=>{
        this.originalItem = {
          ...r.data
        };
        this.editedItem = r.data;
        editItemHelper(this.editedItem,this.listModel);
        this.clubLoaded = true;
      });*/
    },
    async loadHomeContent() {
      //reset from local storage in case of user switch without reloading
      //HTTP.defaults.headers['Authorization'] = localStorage.getItem('token');
      HTTP.get("/home-html").then(response => {
        try {
          this.homeContent = template(response.data.html.split("&lt;%").join("<%").split("%&gt;").join("%>"))(templateVars);
        } catch (er) {
          this.homeContent = `Error: ${er.message}`;
        }


        this.loaded = true;
      });
    },
  }
};
</script>
